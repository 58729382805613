import * as React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import '@nutmeglabs/falcon-ui/dist/esm/index.css';

import { ReduxState } from 'ducks';
import { Props as EditorProps } from 'components/Editor/Editor';
import { FrequentlyViewedProducts } from 'components/FrequentlyViewedProducts/FrequentlyViewedProducts';
import {
  selectBookingWidgetBannerImageUrls,
  selectBookingWidgetHeaderDescription,
} from 'lib/util/bookingWidgetDesign';
import {
  selectHeaderDescriptionText,
  selectTopPageBannerImageUrl,
} from 'lib/util/privateMarketplace';
import { selectPMPEnabled } from 'ducks/server/settings';

import styles from './EssentialPages.module.css';
import { Section } from '@nutmeglabs/falcon-ui';
import { FalconUIContextProvider } from 'components/FalconUIContextProvider/FalconUIContextProvider';

const SectionView = dynamic(() => import('@nutmeglabs/falcon-ui').then((mod) => mod.SectionView), {
  ssr: false,
});

interface Props {
  path: string;
}

const Editor = dynamic<EditorProps>(
  () => import('components/Editor/Editor').then((mod) => mod.Editor) as any,
  {
    ssr: false,
  }
);

export const CustomPage = ({ path }: Props): any => {
  const router = useRouter();
  const settings = useSelector((state: ReduxState) => state.server.settings.all);
  const customPages = useSelector((state: ReduxState) => state.server.customPages.all);
  const products = useSelector((state: ReduxState) => state.server.productSummaries.all);

  const pmpEnabled = useSelector(selectPMPEnabled);

  const pmpHeaderDescription = useSelector(selectHeaderDescriptionText);
  const pmpOgpImageUrl = useSelector(selectTopPageBannerImageUrl);
  const bookingWidgetHeaderDescription = useSelector(selectBookingWidgetHeaderDescription);
  const bookingWidgetBannerImageUrls = useSelector(selectBookingWidgetBannerImageUrls);
  let headerDescription = '';
  let ogpImageUrl = '';
  if (pmpEnabled) {
    headerDescription = pmpHeaderDescription;
    ogpImageUrl = pmpOgpImageUrl;
  } else {
    headerDescription = bookingWidgetHeaderDescription;
    const bannerImageUrls = bookingWidgetBannerImageUrls;
    if (bannerImageUrls.length > 0) {
      ogpImageUrl = bannerImageUrls[0];
    }
  }

  const ogpSiteName = `${headerDescription} ${settings?.supplier_name}`;
  const customPage = (customPages || []).find((customPage) => {
    return customPage?.path === path;
  });

  const twitter = (settings?.sns_ids || []).find((snsId) => {
    return snsId?.key === 'twitter';
  });

  const pageSections = JSON.parse(customPage?.sections ?? '[]');

  const enableSubScriptionCustomTopPage = useSelector(
    (state: ReduxState) =>
      state.server.settings.all?.subscriptions?.some(
        (subscription) =>
          subscription.key === 'feature-custom-top-page' && subscription.status === 'ACTIVE'
      ) ?? false
  );

  React.useEffect(() => {
    if (!customPage) {
      router.push('/');
    }
  }, [customPage, router]);

  return (
    <>
      <Head>
        <title>{customPage?.title}</title>

        <meta property="og:type" content={'article'} />
        <meta property="og:site_name" content={ogpSiteName} />
        {ogpImageUrl && <meta property="og:image" content={ogpImageUrl} />}
        {customPage?.title && <meta property="og:title" content={customPage?.title} />}
        <meta name="twitter:card" content={'summary_large_image'} />
        {twitter?.id && <meta name="twitter:site" content={`@${twitter.id}`} />}
        {customPage?.enable_no_index && <meta name="robots" content="noindex" />}
      </Head>

      {enableSubScriptionCustomTopPage && pageSections.length > 0 ? (
        <FalconUIContextProvider>
          {pageSections.map((section: Section, index: number) => (
            <SectionView
              key={index}
              data={section}
              products={products as any}
              optimizeImages
              titleTag={index === 0 ? 'h1' : 'h2'}
            />
          ))}
        </FalconUIContextProvider>
      ) : (
        <div className={clsx(styles['base-wrap'], styles['color-txt'], styles['color-bg'])}>
          <main className={clsx(styles['base-main styles.paddingTop32-sp'])}>
            <div className={clsx(styles['base-info'])}>
              <h1 className={clsx(styles['base-info__headline__custompage'])}>
                {customPage?.title}
              </h1>
              <section className={clsx(styles['base-info__frame'])}>
                <Editor key={path} data={customPage?.body ? JSON.parse(customPage?.body) : null} />
              </section>
            </div>
          </main>
        </div>
      )}
      <FrequentlyViewedProducts />
    </>
  );
};
