import * as React from 'react';
import { NextPage } from 'next';
import { Dispatch } from 'redux';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { useSelector } from 'react-redux';

import { PrivateMarketplaceLayout } from 'components/PrivateMarketplaceLayout/PrivateMarketplaceLayout';
import { BookingWidgetLayout } from 'components/BookingWidgetLayout/BookingWidgetLayout';
import { wrapper } from 'ducks';
import {
  fetchSettingsServerSide,
  fetchCustomPagesServerSide,
  fetchCustomTopPageServerSide,
  fetchProductSummariesServerSide,
} from 'ducks/server';
import { fetchConfigForHostname } from 'lib/util/fetchConfigForHostname';
import { withSSR } from 'withSSR';
import { getI18nServerSideProps } from 'i18n';
import { detectLocale } from 'i18n-server';
import { absoluteUrl } from 'lib/util/absoluetUrl';
import { selectPMPEnabled } from 'ducks/server/settings';
import { selectLanguageOptions } from 'ducks/server/settings';
import { CustomPage } from 'components/EssentialPages/CustomPage';
import { OgpSiteName, OgpImageUrl, TwitterSite } from 'components/OgpTags';
import { Section } from '@nutmeglabs/falcon-ui';

interface Props {
  apiKey: string;
  host: string;
  id: string;
}

const Top: NextPage<Props> = ({ host, id }) => {
  const router = useRouter();

  const pmpEnabled = useSelector(selectPMPEnabled);

  return (
    <>
      <Head>
        <meta property="og:url" content={`${host}${router.asPath}`} />
        <meta property="og:type" content={'article'} />
        <meta name="twitter:card" content={'summary_large_image'} />
      </Head>
      {/* 
        Next.js doesn't allow to place components in Head tag, 
      then I place the components using Head tag out of other Head tag */}
      <OgpSiteName />
      <OgpImageUrl />
      <TwitterSite />
      {pmpEnabled ? (
        <PrivateMarketplaceLayout>
          <CustomPage path={id} />
        </PrivateMarketplaceLayout>
      ) : (
        <BookingWidgetLayout>
          <CustomPage path={id} />
        </BookingWidgetLayout>
      )}
    </>
  );
};

export const getServerSideProps = wrapper.getServerSideProps(
  async ({ params, req, res, store }) => {
    const hostname = req.headers?.host?.split(':')[0] || '';
    const { apiKey, lang } = await fetchConfigForHostname(hostname);

    const dispatch: Dispatch<any> = store.dispatch;

    const id = params?.id as string;

    let i18nProps = {};

    let locale = lang;
    if (locale) {
      // If lang is initialized in config, this is a single-language PMP

      await dispatch(fetchSettingsServerSide(apiKey, '', locale, `https://${hostname}`));
    } else {
      locale = await detectLocale(req, res);

      // Fetch settings using detected language
      await dispatch(fetchSettingsServerSide(apiKey, '', locale));

      // Verify that the detected language is in the supplier's supported languages. If it isn't,
      // default to the first supported language and refetch settings.
      const langOptions = selectLanguageOptions(store.getState());
      if (langOptions.length > 0 && !langOptions.some((langOpt) => langOpt.iso === locale)) {
        locale = langOptions[0].iso;
        await dispatch(fetchSettingsServerSide(apiKey, '', locale));
      }
    }

    const fetchRequests = [dispatch(fetchCustomPagesServerSide(apiKey, locale))];

    const isCustomTopPageEnabled = store.getState().server.settings.all?.is_custom_top_page_enabled;

    if (isCustomTopPageEnabled) {
      fetchRequests.push(dispatch(fetchCustomTopPageServerSide(apiKey, locale)));
    }

    await Promise.all(fetchRequests);

    // Load product if custom page includes a custom top page product list
    const customPage = store.getState().server.customPages.all?.find((page) => page.path === id);
    if (customPage?.sections) {
      try {
        const sections: Section[] = JSON.parse(customPage.sections);
        if (sections?.some((section) => section.type === 'PRODUCT_LIST')) {
          await dispatch(fetchProductSummariesServerSide(apiKey, locale));
        }
      } catch (e) {
        console.error(e);
      }
    }

    i18nProps = await getI18nServerSideProps(locale);

    (req as any).locale = locale;

    return {
      props: {
        apiKey,
        host: absoluteUrl(req),
        id,
        ...i18nProps,
      },
    };
  }
);

export default withSSR()(Top);
